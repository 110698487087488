import React from 'react';
import { format } from 'date-fns';

import { Box } from '@core';

import { Text } from '@components';

const LastUpdatedToday = ({ title, padding }) => (
  <Box {...padding}>
    <Text.Body1Strong textAlign="center">
      {title} <Text.Body1Strong as="span">{format(new Date(), 'yyyy-LL-dd')}</Text.Body1Strong>
    </Text.Body1Strong>
  </Box>
);

export default LastUpdatedToday;
